t<template>
  <Dialog v-if="dialogVisible" v-model:visible="dialogVisible" modal :draggable="false" :header='`Current Jira Trackings for ${props.jiraIssue.taskNumber}-${props.jiraIssue.taskName}` '
    position="top" style="max-width: 80%; min-width: 80%; max-height: 80%;">
    <template #default>
      <ConDataTable id-field="trackingId" tableId="JiraTrackingsList" :columns="jiraTrackingTableColumns" v-model:selectedItems="selectedItems" @onSelectionChanged="handleTasksSelectionChange"
        :rowsPerPageOptions="[10, 20, 50]" :onReload="fetchJiraTrackingsAsync" editMode="cell" @onEditComplete="onEditComplete" ref="jiraTrackingTableRef">
        <template #timeSpent="col">
          <Column :field="col.field" :header="col.header">
            <template #body="field">
              {{ field.data[col.field] }}
            </template>
            <template #editor>
              <div class="flex justify-content-between">
                <div class="flex flex-column gap-2">
                  <span class="font-bold text-center">Days:</span>
                  <InputNumber v-model="daysSpent" showButtons buttonLayout="vertical" style="width: 4rem"
                  decrementButtonClassName="p-button-secondary" incrementButtonClassName="p-button-secondary"
                  incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
                </div>
                <div class="flex flex-column gap-2">
                  <span class="font-bold text-center">Hours:</span>
                  <InputNumber v-model="hoursSpent" showButtons buttonLayout="vertical" style="width: 4rem"
                  decrementButtonClassName="p-button-secondary" incrementButtonClassName="p-button-secondary"
                  incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
                </div>
                <div class="flex flex-column gap-2">
                  <span class="font-bold text-center">Minutes:</span>
                  <InputNumber v-model="minutesSpent" showButtons buttonLayout="vertical" style="width: 4rem"
                  decrementButtonClassName="p-button-secondary" incrementButtonClassName="p-button-secondary"
                  incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
                </div>
                    
              </div>
            </template>
          </Column>
        </template>
      </ConDataTable>
      <div style="margin-top: 2rem;">
        <label for="jiraComment">Jira Comment</label>
        <Textarea v-model="comment" class="w-full" id="jiraComment" />  
      </div>

    </template>
    <template #footer>
      <div class="flex flex-1 justify-content-between">
        <Button class="p-button-text" icon="pi pi-times" label="Cancel" :onClick="() => {
          dialogVisible = false;
        }" />
        <div>
          <Button class="p-button-primary" icon="pi pi-trash" label="Delete" @click="dispatchDeleteTrackingAsync" :disabled="trackingIdList.length < 1" />
          <Button class="p-button-primary" icon="pi pi-calculator" label="Summarize" @click="dispatchSummarizeTrackingsAsync" :disabled="trackingIdList.length < 2"/>
          <Button class="p-button-primary" icon="pi pi-plus" label="Commit" :disabled="trackingIdList.length < 1" @click="dispatchCommitTrackingAsync"/>
          <Button class="p-button-primary" icon="pi pi-plus" label="Commit & Remove Task" :disabled="trackingIdList.length < 1" @click="CommitAndDeleteTrackingAsync" />
        </div>

      </div>
    </template>
  </Dialog>
</template>
<script setup lang="ts">
import { ref, watch, type Ref, onMounted } from 'vue';
import Dialog from 'primevue/dialog'
import Button from 'primevue/button';
import { JiraTimeTrackingService, type ListTrackingResponse_Tracking, type ListTrackingResponse_UserIssue} from '@/apis/jupiter-api'
import { ConDataTable } from '@congatec/primevue-components-lib';
import Column from 'primevue/column';
import InputNumber from 'primevue/inputnumber';
import Textarea from 'primevue/textarea';
import { useJiraTokenStore } from "@/earth/store";

const jiraTokenStore = useJiraTokenStore();
let jiraToken = ref(jiraTokenStore.token);
const dialogVisible = ref<boolean>(false);
const daysSpent = ref<number>(0);
const hoursSpent = ref<number>(0);
const minutesSpent = ref<number>(0);
const comment = ref<string>('');
const jiraTrackingTableRef = ref<null | typeof ConDataTable>(null)
const trackingIdList = ref<number[]>([]);
const issue = ref<ListTrackingResponse_UserIssue>();
const jiraTrackingTableColumns = [
  {
    selectionMode: 'multiple',
    field: 'any'
  },
  {
    field: 'trackingId',
    header: 'Tracking Id',
    dataType: 'text',
  },
  {
    field: 'startDate',
    header: 'Start Date',
    dataType: 'date',
  },
  {
    field: 'endDate',
    header: 'End Date',
    dataType: 'date',
  },
  {
    field: 'timeSpent',
    header: 'Time Spent',
    dataType: 'text',
  },
]

const props = defineProps<{
  jiraIssue: ListTrackingResponse_UserIssue,
  widgetConfigId?: number | undefined,
  visible: boolean,
  onHide: (edited: boolean) => any
}>();

const emits = defineEmits(["jiraIssueCommited","jiraIssueCommitedAndRemoved"])
const selectedItems: Ref<any[]> = ref([]);
onMounted(() => {
  selectedItems.value = [];
  issue.value = props.jiraIssue;
  console.log("On Mounted TimeTrackingDialog:", props.jiraIssue)
})

watch(() => props.visible, (nv) => {
  if (nv) {
    dialogVisible.value = nv;
  }
}, { deep: true, immediate: true });

watch(() => dialogVisible.value, (nv) => {
  trackingIdList.value = [];
  jiraTrackingTableRef.value?.forceReload(1, true);
  if (nv != null && !nv) {
    props.onHide(true);
  }
})

watch(() => minutesSpent.value, (nv) =>{
  if(nv > 60){
    hoursSpent.value += 1;
    minutesSpent.value = 0;
  }
})

watch(() => hoursSpent.value, (nv) =>{
  if(nv >= 8){
    daysSpent.value += 1;
    hoursSpent.value = 0;

  }
})

async function fetchJiraTrackingsAsync() : Promise<any> {
  if(issue.value)
  return {
    totalCount: issue.value.trackings?.length,
    data: issue.value.trackings,
  }
}
watch(() => selectedItems.value, (nv) => {
  console.log("selectedItemsEarth:",selectedItems.value)
})
function onEditComplete(index: number, data: any, newValue: any, field: any, tableDataRef: any) {
  console.log("This is the index of the edit: ", index);
  console.log("This is the data of the edit: ", data);
  console.log("This is the newValue of the edit: ", newValue);
  console.log("This is the field of the edit: ", field);
  console.log("This is the tableDataRef of the edit: ", tableDataRef);

  JiraTimeTrackingService.putApiV1JiraTimeTrackings({
    trakingId: data.trackingId,
    daysSpent: daysSpent.value,
    hoursSpent: hoursSpent.value,
    minutesSpent: minutesSpent.value
  })
  tableDataRef.value[index].timeSpent = `${daysSpent.value}d. ${hoursSpent.value}h. ${minutesSpent.value}m.`;
}

function handleTasksSelectionChange(selectedRows: ListTrackingResponse_Tracking[]){
  trackingIdList.value = [];
  selectedItems.value = selectedRows;
  selectedRows.forEach((tracking : ListTrackingResponse_Tracking) =>  {
    if(tracking.trackingId){
      console.log("TrackingIds: ", trackingIdList.value)
      trackingIdList.value.push(tracking.trackingId);
    }
  });
}

async function dispatchSummarizeTrackingsAsync(){
  let newTrackings = await JiraTimeTrackingService.postApiV1JiraTimeTrackingsSummarize({
    trackingIds: trackingIdList.value,
  })
  if(issue.value){
    issue.value.trackings = newTrackings.trackings
  }
  trackingIdList.value = [];
  jiraTrackingTableRef.value?.forceReload(1, true);

}

async function dispatchDeleteTrackingAsync(){
  let newTrackings = await JiraTimeTrackingService.deleteApiV1JiraTimeTrackings({
    trackingIds: trackingIdList.value,
  })
  if(issue.value){
    issue.value.trackings = newTrackings.trackings
  }
  trackingIdList.value = [];
  jiraTrackingTableRef.value?.forceReload(1, true);
}

async function CommitAndDeleteTrackingAsync(){
  await JiraTimeTrackingService.putApiV1JiraTimeTrackingsCommit({
    jiraToken: jiraToken.value,
    trackingIds: trackingIdList.value,
    issueKey: props.jiraIssue.taskNumber,
    jiraComment: comment.value,
    deleteIssue: true
  });
  jiraTrackingTableRef.value?.forceReload(1, true);
  dialogVisible.value = false
  emits('jiraIssueCommitedAndRemoved')
}

async function dispatchCommitTrackingAsync(){
  await JiraTimeTrackingService.putApiV1JiraTimeTrackingsCommit({
    jiraToken: jiraToken.value,
    trackingIds: trackingIdList.value,
    issueKey: props.jiraIssue.taskNumber,
    jiraComment: comment.value,
  });
  jiraTrackingTableRef.value?.forceReload(1, true);
  emits("jiraIssueCommited")
  dialogVisible.value = false
}
</script>
<style scoped>


.p-button.p-button-icon-only {
  min-width: 100% !important;
}
</style>
