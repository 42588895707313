<template>
  <div class="flex flex-column h-full" @click="closeTimeTracker">
    <link rel="stylesheet" :href="theme" />
    <Toast />
    <DynamicDialog id="dynDialog" />
    <TimeBookingDialog
      :jiraIssue="jiraIssueToCommit!"
      @jiraIssueCommited="timeTracker.forceReload()"
      @jiraIssueCommitedAndRemoved="timeTracker.forceReload()"
      v-if="showJiraTimeBookingEdit"
      :visible="showJiraTimeBookingEdit"
      :onHide="
        (_edited: boolean) => {
          showJiraTimeBookingEdit = false;
        }
      "
    />
    <div class="flex flex-column w-full" style="background: pink">
      <ConToolbar
        :home="{ label: 'Home', to: '/' }"
        toolbar-class="p-0 "
        @onSidebarToggle="toggleSidebar()"
        class="w-full h-full"
      >
        <template #start> </template>
        <template #end>
          <Dropdown
            class="mr-5"
            v-if="route.path.includes('uranus')"
            v-model="selectedDwhDatabase"
            :options="dwhDatabases"
            optionLabel="databaseName"
            @change="SwitchDwhDb"
            placeholder="Select a Dwh Database"
            style="height: 40px"
          />
          <div
            class="flex align-items-center justify-content-between pr-6 gap-3"
          >
            <InputText
              size="small"
              placeholder="chat with congaAi"
              v-model="aiChatMessage"
              v-on:keydown.enter.exact.prevent="chatWithAi"
              @keydown.enter.shift.exact.prevent="aiChatMessage + '\n'"
              v-if="!route.fullPath.includes('con-ai')"
            ></InputText>
            <div id="timeTrackerButton">
              <Button
                id="timeTrackerButton"
                icon="pi pi-calendar-clock"
                size="large"
                text
                rounded
                @click="
                  () => {
                    timeTrackerVisible = true;
                  }
                "
              ></Button>
            </div>

            <ConUserButton />
          </div>
        </template>
      </ConToolbar>
    </div>
    <div class="flex flex-row w-full h-full">
      <ConSidebar
        :items="sidebarItems"
        style="font-size: 16px !important"
        :sidebarVisible="sidebarVisible"
        @onSidebarToggle="toggleSidebar()"
      >
      </ConSidebar>
      <div class="flex flex-column h-full flex-grow-1">
        <div
          id="timeTrackerContainer"
          class="w-4 flex flex-row justify-content-end absolute right-0"
          v-if="timeTrackerVisible"
        >
          <TimeTracker
            @commitJiraClick="openEditBeforeCommit"
            v-if="timeTrackerVisible"
            @closeDialog="
              () => {
                timeTrackerVisible = false;
              }
            "
            ref="timeTracker"
          ></TimeTracker>
        </div>
        <RouterView />
      </div>
    </div>
    <ConFileDownloadStatus> </ConFileDownloadStatus>
  </div>
</template>
<script setup lang="ts">
import { RouterView, useRouter } from "vue-router";
import {
  ConSidebar,
  ConToolbar,
  ConUserButton,
  type ConSidebarItemsType,
} from "@congatec/primevue-components-lib";
import type { MenuItem } from "primevue/menuitem";
import { onMounted, ref, watch, type HtmlHTMLAttributes, type Ref } from "vue";
import {
  useBreadCrumbStore,
  useThemeStore,
  ConFileDownloadStatus,
} from "@congatec/primevue-components-lib";
import { Theme } from "@congatec/primevue-components-lib";
import "../node_modules/@congatec/primevue-components-lib/dist/lib/style.css";
import Toast from "primevue/toast";
import {
  useTokenGuard,
  onKeycloakToken,
  useRequestErrorGuard,
  type KeycloakService,
} from "@congatec/authentication-lib";
import axios from "axios";
import { useToast } from "primevue/usetoast";
import { usePrimeVue } from "primevue/config";
import { useRoute } from "vue-router";
import { DwhDbStore } from "@/uranus/components/dataqualitychecks/store/selectedDwhDb";
import type { DwhDatabaseListResponse_DwhDatabase } from "@/apis/uranus-api";
import { DwhDatabaseService } from "@/apis/uranus-api/services/DwhDatabaseService";
import Dropdown from "primevue/dropdown";
import { to } from "@congatec/primevue-components-lib";
import { useEventBroker } from "@congatec/eventbroker-lib";
import globalRouter from "./globalRouter";
import { useGlobalRouterStore } from "./stores/routerStore";
import { useCardStore } from "./stores/cardStore";
import {
  CardsService,
  type CardsListResponse_Card as CardType,
} from "./apis/jupiter-api";
import InputText from "primevue/inputtext";
import Chat from "./azureai/components/ChatComponent.vue";
import Button from "primevue/button";
import DynamicDialog from "primevue/dynamicdialog";
import { useDialog } from "primevue/usedialog";
import TimeTracker from "@/jupiter/components/shared/TimeTracker.vue";
import TimeBookingDialog from "@/jupiter/components/shared/TimeBookingDialog.vue";
import { type ListTrackingResponse_UserIssue } from "@/apis/jupiter-api";
const eventBroker = useEventBroker();
const router = useRouter();
const dialog = useDialog();
const dwhDatabases = ref(new Array<DwhDatabaseListResponse_DwhDatabase>());
const selectedDwhDatabase: Ref<DwhDatabaseListResponse_DwhDatabase> = ref({});
const sidebarVisible = ref(false);
const portalCards = ref<CardType[]>([]);
const cardStore = useCardStore();
const aiChatMessage = ref<string>("");
const jiraIssueToCommit = ref<ListTrackingResponse_UserIssue>();
const showJiraTimeBookingEdit = ref<boolean>(false);
const timeTrackerVisible = ref(false);
const timeTracker = ref();
function toggleSidebar() {
  sidebarVisible.value = !sidebarVisible.value;
}
async function SwitchDwhDb() {
  const request = selectedDwhDatabase.value.id;
  DwhDatabaseService.optionsApiV1DwhDatabaseDwhDatabaseSwitch(request);

  if (selectedDwhDatabase.value.id) {
    console.log(selectedDwhDatabase.value.id);
    DwhDbStore().set(selectedDwhDatabase.value.id);
  }
}

const toast = useToast();

const breadCrumbStore = useBreadCrumbStore();
let sidebarItems: Ref<ConSidebarItemsType[]> = ref([]);
onMounted(() => {
  //setupSidebarItems();
  if (!route.meta.breadcrumb) {
    breadCrumbStore.setTo([{ title: "APP-Portal" }] as [MenuItem]);
  } else {
    breadCrumbStore.setTo(route.meta.breadcrumb as [MenuItem]);
  }
});

const route = useRoute();

watch(
  () => route.path,
  () => {
    breadCrumbStore.setTo(route.meta.breadcrumb as [MenuItem]);
  },
);

const globalRouterStore = useGlobalRouterStore();
globalRouter.beforeEach((to) => {
  if (!to.fullPath.includes("unauthorized")) {
    globalRouterStore.set(to.fullPath);
  }
});

globalRouter.afterEach((to) => {
  if (to.meta.title) {
    eventBroker?.publish("setPageTitle", to.meta.title);
  }
});

const themeStore = useThemeStore();
const theme = ref();
const useTheme = () => {
  if (themeStore.state == Theme.ConDark) {
    theme.value = new URL(
      "../node_modules/@congatec/primevue-components-lib/dist/lib/theme_dark.css",
      import.meta.url,
    ).href;
  } else {
    theme.value = new URL(
      "../node_modules/@congatec/primevue-components-lib/dist/lib/theme_light.css",
      import.meta.url,
    ).href;
  }
};
useTheme();

useRequestErrorGuard((title: string, message: any) => {
  if (message?.response?.data) {
    let res = message.response.data;
    if (res.Error) {
      message = res.Error;
    }
  }

  toast.add({
    severity: "error",
    summary: title,
    detail: message,
    life: 3150,
  });
}, axios);

useTokenGuard(useRouter(), axios, "/unauthorized");

themeStore.$subscribe(useTheme);

const primevue = usePrimeVue();
if (primevue.config.locale) primevue.config.locale.firstDayOfWeek = 1;

const isAdmin = ref(false);
const isNeptuneUser = ref(false);
const isErisUser = ref(false);
const isUranusUser = ref(false);
const isSaturnUser = ref(false);
const isEarthUser = ref(false);
const isAzureAiUser = ref(false);
const isCeresUser = ref(false);
const isMercuryUser = ref(false);
const isReceptionUser = ref(false);
const isHaumeaUser = ref(false);
const isAReadyUser = ref(false);
const newTab = (url: string, externalLink: boolean) => {
  if (externalLink == true) {
    return () => window?.open(url, "_blank")?.focus();
  } else {
    return () => to(url);
  }
};

const getDefaultQuickNavItems = () => {
  return {
    icon: "pi pi-link",
    label: "Quick Navigation",
    class: "mb-5",
    items: [
      {
        label: "confluence",
        command: newTab("https://one.congatec.local:8443/", true),
      },
      {
        label: "Jira",
        command: newTab(
          "https://one.congatec.local:8445/secure/Dashboard.jspa",
          true,
        ),
      },
      {
        label: "Flexopus",
        command: newTab("https://congatec.flexopus.com/", true),
      },
      {
        label: "SAP Fiori",
        command: newTab(
          "https://apperp01.congatec.local:44300/sap/bc/ui2/flp#Shell-home",
          true,
        ),
      },
    ],
  };
};
let quickNavItems = getDefaultQuickNavItems();

onKeycloakToken(async (authenticationService: KeycloakService) => {
  isAdmin.value = authenticationService.hasRoles(
    ["Admin", "SystemAdmin"],
    import.meta.env.VITE_KEYCLOAK_CLIENTID,
  );
  isNeptuneUser.value = authenticationService.hasRoles(
    ["User"],
    import.meta.env.VITE_KEYCLOAK_NEPTUNE_CLIENTID,
  );
  isReceptionUser.value = authenticationService.hasRoles(
    ["Reception"],
    import.meta.env.VITE_KEYCLOAK_NEPTUNE_CLIENTID,
  );
  isErisUser.value = authenticationService.hasRoles(
    ["User"],
    import.meta.env.VITE_KEYCLOAK_ERIS_CLIENTID,
  );
  isUranusUser.value = authenticationService.hasRoles(
    ["User"],
    import.meta.env.VITE_KEYCLOAK_URANUS_CLIENTID,
  );
  isSaturnUser.value = authenticationService.hasRoles(
    ["User"],
    import.meta.env.VITE_KEYCLOAK_SATURN_CLIENTID,
  );
  isEarthUser.value = authenticationService.hasRoles(
    ["User"],
    import.meta.env.VITE_KEYCLOAK_EARTH_CLIENTID,
  );
  isAzureAiUser.value = authenticationService.hasRoles(
    ["Admin", "SystemAdmin", "OpenAIUser"],
    import.meta.env.VITE_KEYCLOAK_AZUREAI_CLIENTID,
  );
  isCeresUser.value = authenticationService.hasRoles(
    ["Admin", "SystemAdmin", "ManageUsers"],
    import.meta.env.VITE_KEYCLOAK_CERES_CLIENTID,
  );
  isMercuryUser.value = authenticationService.hasRoles(
    ["Admin", "SystemAdmin", "SysAdmin", "User"],
    import.meta.env.VITE_KEYCLOAK_MERCURY_CLIENTID,
  );
  isHaumeaUser.value = authenticationService.hasRoles(
    ["User"],
    import.meta.env.VITE_KEYCLOAK_HAUMEA_CLIENTID,
  );
  isAReadyUser.value = authenticationService.hasRoles(
    ["User"],
    import.meta.env.VITE_KEYCLOAK_AREADY_CLIENTID,
  );
  setupSidebarItems();
  portalCards.value = (await CardsService.getApiV1Cards()).cards || [];
  cardStore.set(portalCards.value);
});

const setupSidebarItems = () => {
  quickNavItems = getDefaultQuickNavItems();
  portalCards.value.forEach((card) => {
    if (card.quicknavItem) {
      quickNavItems.items.push({
        label: card.title || "no title",
        command: newTab(card.route || "/", card.externalLink || false),
      });
    }
  });
  if (route.fullPath.includes("report-portal")) {
    setupBiSidebar();
  } else {
    setupAppSidebar();
  }
};

const setupBiSidebar = () => {
  sidebarItems.value = [];
  sidebarItems.value = [quickNavItems];
  portalCards.value.forEach((card) => {
    if (card.sidebarItem) {
      sidebarItems.value.push({
        icon: "pi pi-external-link",
        label: card.title!,
        command: newTab(card.route || "/", card.externalLink || false),
      });
    }
  });
};

const setupAppSidebar = () => {
  sidebarItems.value = [
    quickNavItems,
    {
      icon: "pi pi-warehouse",
      label: "Report-Portal",
      title: "Report-Portal",
      route: "/report-portal",
      command: to("/report-portal"),
    },
    {
      icon: "pi pi-users",
      label: "con-VISIT",
      visible: isNeptuneUser.value,
      command: to("/neptune/visitor-list"),
      items: [
        {
          label: "Visitor List",
          icon: "pi pi-home",
          command: to("/neptune/visitor-list"),
        },
        {
          icon: "pi pi-qrcode",
          label: "Scan QR",
          command: to("/neptune/vs-scan"),
        },
        {
          icon: "pi pi-table",
          label: "Reception Dashboard",
          command: to("/neptune/reception-dashboard"),
          visible: isReceptionUser.value,
        },
        {
          icon: "pi pi-clock",
          label: "Checkins/-outs",
          command: to("/neptune/checkinout-list"),
        },
        {
          icon: "pi pi-send",
          label: "Email",
          command: to("/neptune/email/manage-templates"),
        },
        {
          icon: "pi pi-file",
          label: "Files",
          command: to("/neptune/email/manage-attachments"),
        },
      ],
    },
    {
      icon: "pi pi-file",
      label: "Logfile Extractor",
      visible: isErisUser.value,
      command: to("/eris/home"),
      items: [
        {
          label: "Home",
          icon: "pi pi-home",
          command: to("/eris/home"),
        },
        {
          icon: "pi pi-file-export",
          label: "SVN Revisions Extraction",
          command: to("/eris/admin-svn-revision"),
          visible: isAdmin.value,
        },
        {
          icon: "pi pi-check-square",
          label: "Test Results",
          command: to("/eris/rpt-testresult-list"),
        },
        {
          icon: "pi pi-server",
          label: "Manufactured Products",
          command: to("/eris/manufactured-product-list"),
        },
        {
          icon: "pi pi-download",
          label: "Product Export",
          command: to("/eris/product-export"),
        },
      ],
    },
    {
      icon: "pi pi-database",
      label: "Data Quality Checker",
      visible: isUranusUser.value,
      command: to("/uranus/home"),
      items: [
        {
          icon: "pi pi-home",
          label: "Home",
          command: to("/uranus/home"),
        },
        {
          icon: "pi pi-check-square",
          label: "Data Quality Checks",
          items: [
            {
              label: "Create",
              icon: "pi pi-plus-circle",
              command: to("/uranus/dataqualitychecks/create"),
            },
            {
              label: "Execute",
              icon: "pi pi-play",
              command: to("/uranus/dataqualitychecks/execute"),
            },
            {
              label: "List",
              icon: "pi pi-list",
              command: to("/uranus/dataqualitychecks"),
            },
          ],
        },
        {
          label: "Executions",
          icon: "pi pi-cog",
          command: to("/uranus/executions"),
        },
        {
          label: "DWH Databases",
          icon: "pi pi-database",
          items: [
            {
              label: "Create",
              icon: "pi pi-plus-circle",
              command: to("/uranus/dwhDatabases/create"),
            },
            {
              label: "List",
              icon: "pi pi-list",
              command: to("/uranus/dwhDatabases"),
            },
          ],
        },
        {
          label: "Scheduled Executions",
          icon: "pi pi-clock",
          command: to("/uranus/scheduledexecutions"),
        },
      ],
    },
    {
      icon: "pi pi-id-card",
      label: "con-IDCARD",
      visible: isHaumeaUser.value,
      command: to("/haumea/employee-card-list"),
      items: [
        {
          label: "Home",
          icon: "pi pi-home",
          command: to("/haumea/employee-card-list"),
        },
        {
          icon: "pi pi-image",
          label: "Image Templates",
          command: to("/haumea/image-template"),
          //visible: isAdmin.value,
        },
        {
          icon: "pi pi-images",
          label: "Templates",
          command: to("/haumea/template"),
          //visible: isAdmin.value,
        },
      ],
    },
    {
      icon: "pi pi-warehouse",
      label: "Inventory Management",
      visible: isSaturnUser.value,
      command: to("/saturn/home"),
      items: [
        {
          icon: "pi pi-home",
          label: "Home",
          command: to("/saturn/home"),
        },
        {
          icon: "pi pi-database",
          label: "Samples",
          command: to("/saturn/hw-list"),
        },
        {
          label: "Scan QR",
          icon: "pi pi-qrcode",
          command: to("/saturn/hw-scan"),
        },
        {
          label: "Departments",
          icon: "pi pi-cog",
          command: to("/saturn/departments"),
        },
        {
          label: "Locations",
          icon: "pi pi-cog",
          command: to("/saturn/locations"),
        },
      ],
    },
    {
      icon: "pi pi-table",
      label: "Employee Dashboard",
      visible: isEarthUser.value,
      command: to("/earth/dashboard"),
      items: [
        {
          icon: "pi pi-home",
          label: "Dashboard",
          command: to("/earth/dashboard"),
        },
        {
          icon: "pi pi-shopping-cart",
          label: "Marketplace",
          command: to("/earth/marketplace"),
        },
        {
          label: "Admin",
          icon: "pi pi-star",
          command: to("/earth/admin"),
        },
      ],
    },
    {
      icon: "pi pi-microchip-ai",
      label: "con-AI",
      visible: isAzureAiUser.value,
      command: to("/con-ai/chat"),
      items: [
        {
          icon: "pi pi-home",
          label: "Chat",
          command: to("/con-ai/chat"),
        },
      ],
    },
    {
      icon: "pi pi-crown",
      label: "Customer Portal",
      visible: isCeresUser.value,
      title: "Ceres",
      route: "/ceres/users",
      command: to("/ceres/users"),
      cardIcon: "/NeptuneIcon01.png",
      description: "Customer Portal Admin",
      tags: ["Application"],
      items: [
        {
          icon: "pi pi-home",
          label: "Users",
          command: to("/ceres/users"),
        },
      ],
    },
    {
      icon: "pi pi-calendar",
      label: "Meetingroom Reservation",
      command: to("/mercury/home"),
      //visible: isMercuryUser.value,
      items: [
        {
          icon: "pi pi-calendar",
          label: "Calendar",
          command: to("/mercury/home"),
        },
      ],
    },
    {
      icon: "pi pi-chart-bar",
      label: "Hubspot Interface",
      visible: true,
      title: "Hubspot Gateway",
      route: "/hubspot-gateway/home",
      command: to("/hubspot-gateway/logs"),
      cardIcon: "/NeptuneIcon01.png",
      description: "A Interface between Hubspot and AureaCRM",
      tags: ["Application"],
      items: [
        {
          label: "Log Entries",
          icon: "pi pi-list",
          command: to("/hubspot-gateway/logs"),
        },
      ],
    },
  ];
};

async function fetchDataFromServiceAsync() {
  const request = {
    pageNumber: 1,
    pageSize: 100,
  };
  if (request) {
    DwhDatabaseService.getApiV1DwhDatabase(
      "",
      "",
      request.pageNumber,
      request.pageSize,
      0,
    ).then((r) => {
      if (r?.dwhDatabases) {
        dwhDatabases.value = r.dwhDatabases;
        if (r.dwhDatabases[0]) selectedDwhDatabase.value = r.dwhDatabases[0];
        SwitchDwhDb();
      }
    });
  }
}

const chatWithAi = () => {
  dialog.open(Chat, {
    props: {
      header: "Chat with CongaAi",
      style: {
        width: "80vw",
        height: "80vh",
        background: "var(--surface-card)",
      },
      modal: true,
    },
    data: {
      appChat: aiChatMessage,
    },
    onClose: () => {
      aiChatMessage.value = "";
    },
  });
};
function openEditBeforeCommit(jiraIssue: ListTrackingResponse_UserIssue) {
  jiraIssueToCommit.value = jiraIssue;
  showJiraTimeBookingEdit.value = true;
}

router.beforeEach(async (_to, _from): Promise<any> => {
  if (_to.path.includes("uranus")) {
    await fetchDataFromServiceAsync();
  }
});

router.afterEach((_to, _from) => {
  setupSidebarItems();
});
const closeTimeTracker = (event: MouseEvent) => {
  const timeTrackerContainer = document.getElementById("timeTrackerContainer");
  if (!timeTrackerContainer) {
    return;
  }
  const clickedElement = event.target as HTMLElement;
  const isInsideTimeTracker = timeTrackerContainer.contains(clickedElement);
  const isTimeTrackerButton =
    clickedElement.parentElement!.id === "timeTrackerButton";
  if (!isInsideTimeTracker && !isTimeTrackerButton) {
    timeTrackerVisible.value = false;
  }
};

watch(
  () => cardStore.cards,
  (nv) => {
    console.log("upsi whupsi:", cardStore.cards);
    if (nv) {
      setupSidebarItems();
    }
  },
  { deep: true, immediate: true },
);
</script>

<style>
.p-tieredmenu .p-submenu-list {
  z-index: 10000;
}

.p-dialog-content {
  height: 100%;
}
</style>
